import {
  IND_ONE,
  PATH,
  PRODUCT_URL,
  IND_ONE_SIT,
  PRODUCT_IP,
} from '../../Constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as UserActionType from './userActionTypes';
import { redirectToLogin } from '../Common/CommonAction';

var token = sessionStorage.getItem('token');
var admin_email = sessionStorage.getItem('name');
export const createJsonHeader = (token) => {
  return {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};
export const PasswordUpdate = async (state) => {
  try {
    const aadhardata = await axios.post(
      `${IND_ONE}${PATH.changepassword}`,
      state,
      createJsonHeader(token)
    );
    console.log(aadhardata);
    if (aadhardata.status === 201) {
      toast.success('Successfully changed password!.');
    } else if (aadhardata.status === 400) {
      console.log(aadhardata);
    }
  } catch (error) {
    console.log(error);
    console.log(error.response);
    if (
      error.response &&
      error.response.data.old_password &&
      error.response.data.old_password[0] === 'Wrong password.'
    ) {
      toast.error(error.response.data.old_password[0]);
    } else if (
      error.response &&
      error.response.data.password &&
      error.response.data.password[0] ===
      'Password must be confirmed correctly.'
    ) {
    }
  }
};

export const ForgetPassword = async (state, cb) => {
  console.log(state);
  const data = {
    email: state,
  };
  sessionStorage.setItem('forgetemail', state);
  try {
    const aadhardata = await axios.post(
      `${IND_ONE}${PATH.passwordreset}`,
      data
    );
    console.log(aadhardata);
    if (aadhardata.status === 200) {
      //   dispatch({ type: "FORGET_EMAIL", payload: state });
      toast.success('Successfully Sent Email!... ');
      cb(true);
    } else if (aadhardata.status === 400) {
      console.log(aadhardata.response);
      cb(false);
    }
  } catch (error) {
    // console.log(error.response.data.email[0]);
    if (error && error.response && error.response.data.email) {
      toast.error(error.response.data.email[0]);
    } else {
      toast.error('OOPS!.. Something went Wrong');
    }
    cb(false);
  }
};
export const ConfirmeResetPassword = async (state, passwordkey, history) => {
  const data = {
    password: state.password,
    token: passwordkey,
  };
  sessionStorage.setItem('forgetpassword', state.password);
  try {
    const aadhardata = await axios(`${IND_ONE}${PATH.passwordresetconfirm}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
    console.log(aadhardata);
    if (aadhardata.status === 200) {
      //   dispatch({ type: "USER_PASSWORD", payload: state.password });
      toast.success('You have successfully changed password!..');
      userLogin(state, history);
    }
  } catch (error) {
    if (error.response.status === 400 && error.response.data) {
      toast.error(error.response.data.password[0]);
    } else if (error.response.status === 404) {
      toast.error('Forget Password link is expired.Please try again!..');
    }
    console.log(error.response);
  }
};

export const GettingCount = async (state, cb) => {
  const data1 = {
    email: state.email,
  };
  try {
    axios(`${IND_ONE}${PATH.wrong_pass_return}`, {
      method: 'POST',
      data: data1,
    }).then((res) => {
      if (res.status === 200) {
        cb(res.data, true);
      }
      console.log(res);
    });
  } catch (error) {
    if (error && error.response) {
      cb(false);
      alert(error.response.data.message);
    }
  }
};
export const UpdateCount = async (state, count, cb) => {
  const inccount = count + 1;
  const data1 = {
    email: state.email,
    upd_count: inccount,
  };
  try {
    axios(`${IND_ONE}${PATH.wrong_pass_update}`, {
      method: 'POST',
      data: data1,
    }).then((res) => {
      console.log(res);
    });
  } catch (error) {
    if (error && error.response) {
      alert(error.response.data.message);
    }
  }
};

export const userLogin = async (state, history, cb, errorcallback) => {
  //   const { forgetemail, userpassword } = getState().userdetails;
  const forgetpassword = sessionStorage.getItem('forgetpassword');
  const forgetemail = sessionStorage.getItem('forgetemail');
  const data = {
    email: state.email || forgetemail,
    password: state.password || forgetpassword,
  };
  try {
    const login = await axios.post(`${IND_ONE}${'users/token/'}`, data);
    console.log(login);
    if (login.status === 200) {
      console.log(login.data.access);
      sessionStorage.setItem('token', login.data.access);
      sessionStorage.setItem('name', data.email);
      // dispatch({
      //   type: UserActionType.REVIEW_DASHBOARD,
      //   payload: maindata,
      // });

      // if (state.password.length > 8) {
      //   history.push("/Products");
      // }
      // history.push("/payslip");
      // window.location.href = "/";
      if (forgetemail && forgetpassword) {
        window.location.href = '/';
      }

      cb(true);
    } else if (login.status === 400) {
      console.log(login);
      cb(false);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.detail === 'Password is incorrect') {
        cb(true);
      } else if (error.response.status === 401) {
        // redirectToLogin();
        cb(false);
        if (error.response.data?.error_message) {
          toast.error(error.response.data.error_message);
        } else {
          toast.error(error.response.data?.message);
        }
      }
    }
  }
};

export const userLogOut = async() =>{
  let token = sessionStorage.getItem('token');
  let email = sessionStorage.getItem('name');

  const data = {
    email,
    token
  }

  const args = {
    method: 'POST',
    url: `${IND_ONE}${PATH.Logout}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data
  };

  try {
    let api = await axios(args);
    if(api.status === 200){
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error)
  }
};

export const isAdmin = () => async (dispatch) => {
  let token = sessionStorage.getItem('token');
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.isAdmin}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      // dispatch({
      //   type: UserActionType.IS_ADMIN,
      //   payload: JSON.parse(api.data.is_admin),
      // });
      if (api?.data?.is_admin === true) {
        sessionStorage.setItem('type', 'admin');
      } else {
        sessionStorage.setItem('type', 'processor');
      }
      console.log(api.data.is_admin, 'api.data.is_admin');
      // alert(JSON.stringify(api.data))
      return api?.data?.is_admin;
    } else {
      // toast.error('Something Went Wrong');
      toast.error(api?.data?.message);
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const Registration = async (values, cb) => {
  const { firstName, lastName, password, phoneNumber, email } = values;
  const data = {
    first_name: firstName,
    last_name: lastName,
    password: password,
    email: email,
    phone: phoneNumber,
    application: 'credit',
  };

  try {
    const registration = await axios.post(
      `${IND_ONE}${PATH.Registartion}`,
      data
    );
    console.log(registration);
    if (registration.status === 201) {
      toast.success(registration.data.message);
      cb(false);
    } else {
      toast.error('Registration Failed! ,Try again after sometime.');
      cb(false);
    }
  } catch (error) {
    cb(false);
    console.log(error.response);
    if (error && error.response) {
      toast.error(error.response.data.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
    } else {
      toast.error('Registration Failed!..');
    }
  }
};

export const userLogout = () => async (dispatch, getState) => {
  dispatch({ type: 'LOGOUT' });
};

export const ReviewDashboard = (
  // page_no = 1, rows_per_page = 25
  page_no = sessionStorage.getItem('page') ? parseInt(sessionStorage.getItem('page')) + 1 : 1 , rows_per_page = sessionStorage.getItem('rows_per_page') ? parseInt(sessionStorage.getItem('rows_per_page')) : 25
  ) => async (dispatch, getState) => {
  const data1 = {
    user_email: admin_email,
    // "geetha.kannan@in-d.ai",
    page_no,
    rows_per_page,
  };
  let args = {
    url: `${PRODUCT_URL}${PATH.reviewStatus}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };

  try {
    let api = await axios(args);
    if (api.status === 200) {
      let maindata = api.data.result.map((e) => [
        e.cs_id,
        e.applicant_name,
        e.channel,
        e.received_at,
        e.reviewed_at,
        e.classification_flags.total_documents,
        e.status,
        e.application_number ? e.application_number : 'NIL',
        //each.extraction_flags,
        //Object.entries(each.classification_flags),
          // {
          //   extraction: Object.values(each.extraction_flags)
          // }
      ]);
      sessionStorage.setItem('total_count', api.data.total_cases);
      dispatch({
        type: UserActionType.REVIEW_DASHBOARD_LENGTH,
        payload: api.data.total_cases,
      });
      dispatch({
        type: UserActionType.REVIEW_DASHBOARD,
        payload: maindata,
      });
      console.log(api.data.result);
      return true;
    }
  } catch (error) {
    // console.log('errorororr', error.response);
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const ToBeReviewDashboard = (
  page_no = sessionStorage.getItem('page') ? parseInt(sessionStorage.getItem('page')) + 1 : 1 , rows_per_page = sessionStorage.getItem('rows_per_page') ? parseInt(sessionStorage.getItem('rows_per_page')) : 25
  ) => async (dispatch, getState) => {
  const data1 = {
    user_email: admin_email,
    // "geetha.kannan@in-d.ai",
    page_no,
    rows_per_page,
  };
  let args = {
    url: `${PRODUCT_URL}${PATH.toBeReviewStatus}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };

  try {
    let api = await axios(args);
    if (api.status === 200) {
      let maindata = api.data.result.map((e) => [
        e.cs_id,
          e.applicant_name,
          e.channel,
          e.received_at,
          e.modified_at,
          e.classification_flags.total_documents,
          e.status,
          e.application_number ? e.application_number : 'NIL',
      ]);
      sessionStorage.setItem('total_count', api.data.total_cases);
      dispatch({
        type: UserActionType.TO_BE_REVIEW_DASHBOARD_LENGTH,
        payload: api.data.total_cases,
      });
      dispatch({
        type: UserActionType.TO_BE_REVIEW_DASHBOARD,
        payload: maindata,
      });
      console.log(api.data.result);
      return true;
    }
  } catch (error) {
    // console.log('errorororr', error.response);
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const PasswordRequired = (page_no = 1, rows_per_page = 25) => async (dispatch, getState) => {
  const data1 = {
    user_email: admin_email,
    // "geetha.kannan@in-d.ai",
    page_no,
    rows_per_page,
  };
  let args = {
    url: `${PRODUCT_URL}${PATH.passwordStatus}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };

  try {
    let api = await axios(args);
    if (api.status === 200) {
      let maindata = api.data.result.map((e) => [
        e.cs_id,
        e.applicant_name,
        e.channel,
        e.received_at,
        e.modified_at,
        e.classification_flags.total_documents,
        e.status,
        e.password_files,
        e.application_number ? e.application_number : 'NIL',
      ]);
      sessionStorage.setItem('total_count', api.data.total_cases);
      dispatch({
        type: UserActionType.PASSWORD_REQUIRED_LENGTH,
        payload: api.data.total_cases,
      });
      dispatch({
        type: UserActionType.PASSWORD_REQUIRED,
        payload: maindata,
      });
      console.log(api.data.result);
      return true;
    }
  } catch (error) {
    // console.log('errorororr', error.response);
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const RejectedDashboard = (page_no = 1, rows_per_page = 25) => async (dispatch) => {
 
  const data1 = {
    user_email: admin_email,
    // subashini@in-d.ai
    page_no,
    rows_per_page,
  };
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.rejectedStatus}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      console.log(api);
      api.data.result.map((e) => {
        data.push([
          e.cs_id,
          e.applicant_name,
          e.channel,
          e.received_at,
          e.rejected_at,
          e.classification_flags.total_documents,
          e.status,
          e.application_number ? e.application_number : 'NIL',
        ]);
      });
      sessionStorage.setItem('total_count', api.data.total_cases);

      dispatch({
        type: UserActionType.REJECTED_STATUS_LENGTH,
        payload: api.data.total_cases,
      });
      dispatch({
        type: UserActionType.REJECTED_STATUS,
        payload: data,
      });
      return true;
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: UserActionType.REJECTED_STATUS,
        payload: [],
      });
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const PendingDashboard = (
  //page_no = 1, rows_per_page = 25
  page_no = sessionStorage.getItem('page') ? parseInt(sessionStorage.getItem('page')) + 1 : 1 , rows_per_page = sessionStorage.getItem('rows_per_page') ? parseInt(sessionStorage.getItem('rows_per_page')) : 25
  ) => async (dispatch) => {
  const data1 = {
    user_email: admin_email,
    page_no,
    rows_per_page,
  };
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.pendingstatus}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      let pending = [];

      console.log(api);
      api.data.result.map((e) => {
        data.push([
          e.cs_id,
          e.applicant_name,
          e.channel,
          e.received_at,
          e.modified_at,
          e.classification_flags.total_documents,
          e.status,
          e.application_number ? e.application_number : 'NIL',
          // e.password_files,
          // e.modified_at,
          // e.modified_by,
          //Object.entries(e.classification_flags),
          // {
          //   classification: Object.values(e.classification_flags)
          // }
          // e?.classification_flags &&
          //   Object.isObject(e.classification_flags) &&
          //   Object.values(e.classification_flags),
        ]);
      });
      sessionStorage.setItem('total_count', api.data.total_cases);

      pending=data.filter((e) => {
            return e[6]==="Pending";
            
          });
      
      dispatch({
        type: UserActionType.PENDING_ITEMS,
        payload: pending,
      });
      dispatch({
        type: UserActionType.PENDING_DASHBOARD_LENGTH,
        payload: api.data.total_cases,
      });
      dispatch({
        type: UserActionType.PENDING_DASHBOARD,
        payload: data,
      });
      return true;
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: UserActionType.PENDING_DASHBOARD,
        payload: [],
      });
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const Search = (activeidx,search_term) => async (dispatch, getState) => {
  const data1 = {
    // user_email: admin_email,
    // "geetha.kannan@in-d.ai",
  activeidx,
  search_term
  };
  let args = {
    url: `${PRODUCT_URL}${PATH.searchStatus}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };

  try {
    let api = await axios(args);
    if (api.status === 200) {
      let maindata = api.data.result.map((e) => [
        e.cs_id,
          e.applicant_name,
          e.channel,
          e.received_at,
          e.modified_at,
          e.classification_flags.total_documents,
          e.status,
      ]);
      console.log("search maindata:", maindata)
      dispatch({
        type: UserActionType.SEARCH_STATUS,
        payload: maindata,
      });
      dispatch({
        type: UserActionType.SEARCH_LENGTH,
        payload: api.data.total_cases,
      });
      console.log(api.data.result);
      return true;
    }
  } catch (error) {
    // console.log('errorororr', error.response);
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Without pagination
// export const AdminUploadDashboard = () => async (dispatch) => {
//   const data1 = {
//     user_email: admin_email,
//     page_no: 1,
//     rows_per_page: 2,
//   };
//   const args = {
//     method: 'POST',
//     url: `${PRODUCT_URL}${PATH.admin_dashboard}`,
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${token}`,
//     },
//     data: data1,
//   };
//   try {
//     let api = await axios(args);
//     if (api.status == 200) {
//       let data = [];
//       console.log(api);
//       api.data.result.map((e) => {
//         data.push([
//           e.cs_id,
//           e.received_at,
//           e.channel,
//           e.queue_type,
//           e.modified_by,
//           e.total_documents,
//           e.status,
//           e.password_files,
//           // e.review_flag + e.delete_flag + e.export_flag,
//           // e.modified_at,
//         ]);
//       });

//       dispatch({
//         type: UserActionType.ADMIN_UPLOAD_DASHBOARD,
//         payload: data,
//       });
//       return true;
//     } else {
//       let message =
//         api.status === 401 ? api.data.message : 'Something Went Wrong';
//       toast.error(message);
//       dispatch({
//         type: UserActionType.ADMIN_UPLOAD_DASHBOARD,
//         payload: [],
//       });
//       return false;
//     }
//   } catch (error) {
//     if (error && error.response) {
//       dispatch({
//         type: UserActionType.ADMIN_UPLOAD_DASHBOARD,
//         payload: [],
//       });
//       toast.error(error?.response?.data?.message);
//       if (error.response.status === 401) {
//         redirectToLogin();
//       }
//       return false;
//     }
//   }
// };
// * with pagination
export const AdminUploadDashboard =
  (page_no, rows_per_page = 25) =>
    async (dispatch) => {
      const data = {
        // user_email: admin_email,
        page_no,
        rows_per_page,
      };
      const args = {
        method: 'POST',
        url: `${PRODUCT_URL}${PATH.admin_dashboard_batch}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      try {
        let api = await axios(args);
        if (api.status == 200) {
          let data = [];
          console.log(api);
          api.data.result.map((e) => {
            data.push([
              e.cs_id,
              e.received_at,
              e.channel,
              e.queue_type,
              e.modified_by,
              e.total_documents,
              e.status,
              e.password_files,
              Object.values(e.document_types),
              // e?.document_types &&
              //   Object.isObject(e.document_types) &&
              //   Object.values(e.document_types),
              // e.review_flag + e.delete_flag + e.export_flag,
              // e.modified_at,
            ]);
          });
          sessionStorage.setItem('total_count', api.data.total_cases);
          dispatch({
            type: UserActionType.ADMIN_UPLOAD_DASHBOARD,
            payload: data,
          });
          return true;
        } else {
          let message =
            api.status === 401 ? api.data.message : 'Something Went Wrong';
          toast.error(message);
          dispatch({
            type: UserActionType.ADMIN_UPLOAD_DASHBOARD,
            payload: [],
          });
          return false;
        }
      } catch (error) {
        if (error && error.response) {
          dispatch({
            type: UserActionType.ADMIN_UPLOAD_DASHBOARD,
            payload: [],
          });
          toast.error(error?.response?.data?.message);
          if (error.response.status === 401) {
            redirectToLogin();
          }
          return false;
        }
      }
    };
// * Open cases for admin
export const OpenCasesDashboard =
  (page_no, rows_per_page = 25) =>
    async (dispatch) => {
      const data = {
        // user_email: admin_email,
        page_no,
        rows_per_page,
      };
      const args = {
        method: 'POST',
        url: `${PRODUCT_URL}${PATH.open_cases_dashboard}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      try {
        let api = await axios(args);
        if (api.status == 200) {
          let data = [];
          console.log(api);
          api.data.result
            // .filter((e) => e.status === 'Rejected')
            .map((e) => {
              data.push([
                e.cs_id,
                e.received_at,
                e.rejected_at,
                e.channel,
                e.queue_type,
                e.modified_by,
                e.total_documents,
                e.status,
                e.rejectionMessage,
                e.password_files,
                // e.review_flag + e.delete_flag + e.export_flag,
                // e.modified_at,
              ]);
            });
          sessionStorage.setItem('total_count', api.data.result.length);
          dispatch({
            type: UserActionType.OPEN_CASES_DASHBOARD,
            payload: data,
          });
          return true;
        } else {
          let message =
            api.status === 401 ? api.data.message : 'Something Went Wrong';
          toast.error(message);
          dispatch({
            type: UserActionType.OPEN_CASES_DASHBOARD,
            payload: [],
          });
          return false;
        }
      } catch (error) {
        if (error && error.response) {
          dispatch({
            type: UserActionType.OPEN_CASES_DASHBOARD,
            payload: [],
          });
          toast.error(error?.response?.data?.message);
          if (error.response.status === 401) {
            redirectToLogin();
          }
          return false;
        }
      }
    };

export const ExportDashboard = (page_no = 1, rows_per_page = 25) => async (dispatch) => {
  const data1 = {
    user_email: admin_email,
    page_no,
    rows_per_page,
  };
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.exportedstatus}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      console.log(api);
      api.data.result.map((e) => {
        data.push([
          e.cs_id,
          e.applicant_name,
          e.channel,
          e.received_at,
          e.modified_at,
          e.classification_flags.total_documents,
          e.new_status ? 'Yes' : 'No',
          e.application_number ? e.application_number : 'NIL',
          //Object.values(e.classification_flags),
        ]);
      });
      sessionStorage.setItem('total_count', api.data.total_cases);

      dispatch({
        type: UserActionType.EXPORT_STATUS_LENGTH,
        payload: api.data.total_cases,
      });
      dispatch({
        type: UserActionType.EXPORT_STATUS,
        payload: data,
      });
      return true;
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: UserActionType.EXPORT_STATUS,
        payload: [],
      });
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const DeleteDashboard = (page_no = 1, rows_per_page = 25) => async (dispatch) => {
  const data1 = {
    user_email: admin_email,
    // subashini@in-d.ai
    page_no,
    rows_per_page,
  };
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.deletestatus}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      console.log(api);
      api.data.result.map((e) => {
        data.push([
          e.cs_id,
          e.applicant_name,
          e.channel,
          e.received_at,
          e.modified_at,
          e.classification_flags.total_documents,
          e.status,
          e.application_number ? e.application_number : 'NIL',
        ]);
      });
      sessionStorage.setItem('total_count', api.data.total_cases);

      dispatch({
        type: UserActionType.DELETE_STATUS_LENGTH,
        payload: api.data.total_cases,
      });
      dispatch({
        type: UserActionType.DELETE_STATUS,
        payload: data,
      });
      return true;
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: UserActionType.DELETE_STATUS,
        payload: [],
      });
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const DeleteDocuments = (job_id) => async (dispatch) => {
  const data1 = { cs_id: job_id };
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.deletedocuments}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success('Deleted successfully');
      return true;
    } else {
      toast.error('Something Went Wrong');

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const ZipBatch = (job_id) => async (dispatch) => {
  const data1 = { job_id_list: job_id, user_email: '' };
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.zipbatch}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data.Zip_file);
      exportPath(api.data.Zip_file);
      // window.open(`https://creditdemo.in-d.ai${api.data.Zip_file}`);
      // toast.success("Deleted successfully");
      // return true;
    } else {
      toast.error('Something Went Wrong');

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const exportPath = (zippath, key) => {
  console.log('exportPath', { key, zippath });
  try {
    axios(`${PRODUCT_IP}${zippath}`, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let filePath = zippath.split('/')[zippath.split('/').length - 1];
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          // if (key === 'JSON') {
          //   link.setAttribute('download', filePath);
          // } else {
          //   link.setAttribute('download', filePath); //or any other extension
          // }
          link.setAttribute('download', filePath);
          document.body.appendChild(link);
          link.click();
          console.log(link);
        } else if (res.status === 201) {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error && error.response) {
          if (error.response.status === 401) {
            toast.error(error.response.data.message);

            redirectToLogin();
          }
        } else {
          toast.error('500 Internal Server Error');
        }
      });
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
// Delete Case ID
export const deleteCaseId = async (caseIds) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.delete_caseId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: { cs_id: caseIds },
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data);
      // toast.success(`Case Id ${caseIds} is deleted`);
      toast.success(`Successful`);
      return true;
    } else {
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Reassign Processor
export const reassignProcessor = async (data) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.reassign_processor}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data);
      toast.success(api.data.message);
      return true;
    } else {
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const handleSearch = (searchTerm) => (dispatch) => {
  console.log('handleSearch', searchTerm);
  dispatch({ type: 'TRACK_SERCH_TERM', payload: searchTerm });
};

export const handleResetSearchTerm = () => (dispatch) => {
  dispatch({ type: 'CLEAR_SERCH_TERM', payload: '' });
};

// * Export as excel And Json
export const exportAsExcelJson = (caseIds) => async (dispatch) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.export_caseid_excel_json}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: { request_id: 'hdfc_id', cs_id: caseIds },
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data);
      toast.success(api.data.message);
      exportPath(api.data['Zip Files']);
      return true;
    } else {
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Export as excel / json
export const exportHandling = (caseIds, key) => async (dispatch) => {
  // console.log('exportHandling',caseIds,key)
  let path = key === 'JSON' ? PATH.export_json_case : PATH.export_excel_case;
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${path}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: { cs_id: caseIds },
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data);
      toast.success(api.data.message);
      if (key === 'JSON') {
        for (let i = 0; i < api.data.Zip_file.length; i++) {
          exportPath(api.data.Zip_file[i], 'JSON');
        }
      } else {
        for (let i = 0; i < api.data['Zip Files'].length; i++) {
          exportPath(api.data['Zip Files'][i], 'XLSX');
          // exportPath(
          //   api.data.Zip_file[i].replace(
          //     '/home/azureuser/Credit/credit_app',
          //     ''
          //   ),
          //   'XLSX'
          // );
        }
      }
      // return true;
    } else {
      toast.error(api.data.message);

      // return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      // return false;
    }
  }
};

// * Unlock pdf
export const unlockPdf = (data, callback) => async (dispatch) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.get_password}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data);
      toast.success(api.data.message);
      callback(api.data, true);
      return true;
    } else {
      callback(api.data, false);
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      callback(error.response.data, false);
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
// * Recover API
export const recoverCaseId = (data) => async (dispatch) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.recover_caseid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: {
      cs_id: data,
    },
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data);
      toast.success(api.data.message);
      return true;
    } else {
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * MIS Dashboard (Create a separate action,reducer and state for MIS)
export const getMISDashboard = (data) => async (dispatch) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${PATH.mis_dashboard}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: { ...data, admin_email }
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log('MIS Dashboard', api.data);
      dispatch({
        type: UserActionType.GET_MIS_DASHBOARD,
        payload: api.data
      })
      toast.success(api.data);
      return true;
    } else {
      dispatch({
        type: UserActionType.GET_MIS_DASHBOARD,
        payload: {}
      })
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
// * MIS Export (Create a separate action,reducer and state for MIS)
export const exportMISReport = (data, value) => async (dispatch) => {
  const endPoint = value === 'PDF' ? PATH.export_mis_pdf_report : PATH.export_mis_excel_report;
  const args = {
    method: 'POST',
    url: `${PRODUCT_URL}${endPoint}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: { ...data, admin_email }
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log('MIS Report', api.data);
      exportPath(api.data.Zip_file, value);
      toast.success(api.data.message);
      return true;
    } else {
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};