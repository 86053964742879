import React from 'react';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import './Header.css';
import { useLocation, useHistory } from 'react-router-dom';
import back from '../../Images/back.svg';
import { resetPreProcessing } from '../../Store/PreProcessing/PreprocessingActions';
import { useDispatch } from 'react-redux';

const Header = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = location;
  console.log('pathname', pathname);
  const { header } = props;
  return (
    <div className='headerClass d-flex justify-content-between'>
      <div className='d-flex align-items-center'>
        {pathname === '/digitizeview' || pathname === '/editdocument' ? (
          <img
            onClick={() => {
              if (pathname === '/editdocument') {
                dispatch(resetPreProcessing());
                if(parseInt(sessionStorage.getItem('activeIndex'))===3){
                  sessionStorage.setItem('activeIndex', 3);
                }
                else{
                  sessionStorage.setItem('activeIndex', 2);
                }
                
                history.push({
                  pathname: '/processordashboard',
                });
              } 
              else if(pathname === '/digitizeview'){
                if(sessionStorage.getItem('activeIndex')==0){
                  history.push({
                    pathname: '/processordashboard',
                  });
                }
                else{
                  sessionStorage.setItem('activeIndex', 1);
                  history.push({
                    pathname: '/processordashboard',
                  });
                }
               
              }
              // else {
              //   sessionStorage.setItem('activeIndex', 0);
              //   history.push({
              //     pathname: '/processordashboard',
              //   });
              // }
            }}
            className='mr-2'
            style={{
              marginTop: '-3px',
              cursor: 'pointer',
            }}
            height={20}
            width={14}
            src={back}
            alt=''
          />
        ) : null}
        <div>{props.header}</div>
      </div>
      {props.children}
    </div>
  );
};

export default Header;
