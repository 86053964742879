// * Route based code splitting (Performance Optimization)
import React, { useState, useEffect, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import IncomeanalysisLayout from '../Layouts/CustomLayout';
import ProcessorLayout from '../Layouts/ProcessorLayout';
import ClientLayout from '../Layouts/ClientLayout';
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Components/Loader/Loader';
import UserLeaveConfirmation from '../Components/Modal/UserLeaveConfirmation';

const LazyLogin = React.lazy(() => import('../Containers/Login/Login'));
const LazySettings = React.lazy(() =>
  import('../Containers/Settings/Settings')
);
const LazyProcessers = React.lazy(() =>
  import('../Containers/Processers/Processers')
);
const LazyDocuments = React.lazy(() =>
  import('../Containers/Documents/Documents')
);
const LazyFields = React.lazy(() => import('../Containers/Fields/Fields'));
const LazyProcessorDashboard = React.lazy(() =>
  import('../ProcessorContainer/ProcessorDashboard/ProcessorDashboard')
);
const LazyEditDocument = React.lazy(() =>
  import('../ProcessorContainer/EditDocument/EditDocument')
);
const LazyDigitizeView = React.lazy(() =>
  import('../Containers/DigitizeView/DigitizeView')
);
const LazyAnalysis = React.lazy(() =>
  import('../Containers/Analysis/Analysis')
);
const LazyQueues = React.lazy(() => import('../Containers/Queues/Queues'));
const LazyAdminUpload = React.lazy(() =>
  import('../Containers/AdminUpload/AdminUpload')
);
const LazyOpenCases = React.lazy(() =>
  import('../Containers/AdminUpload/OpenCases')
);
const LazyMISDashboard = React.lazy(() =>
  import('../Containers/MIS/MISDashboard')
);
const LazyActivateProcessor = React.lazy(() =>
  import('../Containers/ActivateProcessor/ActivateProcessor')
);
const LazyThankyou = React.lazy(() =>
  import('../Containers/Thankyou/Thankyou')
);

// Client Imports
const LazyClientUpload = React.lazy(() =>
  import('../Containers/ClientDashboard/ClientUpload')
);
const LazyClientOpenCases = React.lazy(() =>
  import('../Containers/ClientDashboard/ClientOpenCases')
);

const theme = createTheme({
  palette: {
    primary: {
      light: '#00716e',
      main: '#00716e',
      dark: '#00716e',
    },
    secondary: {
      light: '#00716e',
      main: '#00716e',
      dark: '#00716e',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        backgroundColor: '#f5f5f5',
        '&$selected': {
          backgroundColor: '#048C881A !important',
          zIndex: 100,
        },
      },
    },
    MuiInput: {
      underline: {
        '&&&&:hover:before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        },
      },
    },
  },
});
function HomeRoutes() {
  const isAdmin = useSelector((state) => state?.user?.isAdmin);
  const [flagType, setFlagType] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem('type') == 'admin') {
      // history.push('/queues')
      // setFlagType(true);
      setFlagType('admin');
    } else if (sessionStorage.getItem('type') == 'processor') {
      setFlagType('processor');
    } else {
      setFlagType('client');
    }
    // else{
    //   history.push('/processordashboard')
    // }
  }, [sessionStorage.getItem('type')]);
  console.log(flagType);
  return (
    <Switch>
      {flagType === 'admin' ? (
        <>
          <IncomeanalysisLayout exact path='/' component={LazyQueues} />
          <IncomeanalysisLayout
            exact
            path='/queuesMapProcessors'
            component={LazySettings}
          />
          <IncomeanalysisLayout
            exact
            path='/processers'
            component={LazyProcessers}
          />
          <IncomeanalysisLayout
            exact
            path='/documents'
            component={LazyDocuments}
          />
          <IncomeanalysisLayout exact path='/fields' component={LazyFields} />
          <IncomeanalysisLayout
            exact
            path='/admin-upload'
            component={LazyAdminUpload}
          />
          <IncomeanalysisLayout
            exact
            path='/open-cases'
            component={LazyOpenCases}
          />
          <IncomeanalysisLayout
            exact
            path='/mis-dashboard'
            component={LazyMISDashboard}
          />
          <ClientLayout
            exact
            path='/client/client-upload'
            component={LazyClientUpload}
          />
        </>
      ) : (
        <>
          <ProcessorLayout
            exact
            path='/processordashboard'
            component={LazyProcessorDashboard}
          />
          <ProcessorLayout
            exact
            path='/editdocument'
            component={LazyEditDocument}
          />
          <ProcessorLayout
            exact
            path='/digitizeview'
            component={LazyDigitizeView}
          />
          <ProcessorLayout exact path='/graphs' component={LazyAnalysis} />
        </>
      )
        //  : (
        //   <>
        //     <ClientLayout
        //       exact
        //       path='/client/client-upload'
        //       component={LazyClientUpload}
        //     />
        //     <ClientLayout
        //       exact
        //       path='/client/open-cases'
        //       component={LazyClientOpenCases}
        //     />
        //   </>
        // )
      }
    </Switch>
  );
}

function AuthRoutes() {
  return (
    <Switch>
      <Route path='/' exact component={LazyLogin} />
      <Route path='/register' component={LazyActivateProcessor} />
      <Route exact path='/thankyou' component={LazyThankyou} />
    </Switch>
  );
}

const RootRouter = () => {
  const token = sessionStorage.getItem('token');
  const Authenticated = token;
  const [confirmOpen, setConfirmOpen] = useState(true)
  const dispatch = useDispatch()

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <div>
        <Suspense fallback={<Loader msg='Loading...' />}>
          <Router getUserConfirmation={(message, callback) => {
            console.log('getUserConfirmation', {
              message, confirmOpen,
            });
            return UserLeaveConfirmation(
              message,
              callback,
              confirmOpen,
              setConfirmOpen,
              dispatch
            );
          }}>{Authenticated ? <HomeRoutes /> : <AuthRoutes />}</Router>
        </Suspense>
      </div>
    </ThemeProvider>
  );
};
export default RootRouter;
