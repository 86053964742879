export const IND_ONE = 'https://one.in-d.ai/api/';
export const IND_ONE_SIT = 'https://onesit.in-d.ai/api/';
// export const PRODUCT_URL = 'https://creditdemo.in-d.ai/credit/';
// export const PRODUCT_IP = 'https://creditdemo.in-d.ai/';
export const PRODUCT_URL = 'https://intain-idp.in-d.ai/credit/';
export const PRODUCT_IP = 'https://intain-idp.in-d.ai/';

export const PATH = {
  Login: 'users/token/',
  Logout: 'users_logout/logout_user/',
  isAdmin: 'credit_login',
  wrong_pass_return: 'users/wrong_pass_return/',
  wrong_pass_update: 'users/wrong_pass_update/',
  profiledetails: 'users/get_user_details/',
  passwordexpiry: 'users/pass_expiry_check/',
  profileupdate: 'users/update_user_details/',
  changepassword: 'change_password/',
  passwordreset: 'password_reset/',
  passwordresetconfirm: 'password_reset/confirm/',
  Registartion: 'users/',
  export_caseid_excel_json: 'export_caseid_excel',
  // export_excel_case: 'export-report-excel',
  export_excel_case: 'export_excel_case',
  export_json_case: 'export_json_case',
  // display_processor: "display_processor",
  display_processor_batch: 'displayprocessor_batch',
  display_processor: 'display_processor',
  add_processor: 'add_processor',
  delete_processor: 'delete_processor',
  edit_processor: 'edit_processor',
  // fields
  display_fields: 'display_fields',
  add_fields: 'add_fields',
  update_fields: 'edit_fields',
  delete_field: 'delete_field',
  // documents
  displaydocument_batch: 'displaydocument_batch',
  display_document: 'display_document',
  add_document: 'add_document',
  delete_document: 'delete_document',
  fields_document: 'fields_processor',
  edit_document: 'edit_document',
  // display_queues: "display_queues",
  display_queues_batch: 'displayqueue_batch',
  display_queues: 'display_queues',
  add_queues: 'add_queues',
  map_processor: 'map_processor',
  unmap_processor: 'unmap_processor',
  map_document: 'map_document',
  unmap_document: 'unmap_document',
  delete_queues: 'delete_queues',
  edit_queue: 'edit_queue',
  queues_and_documents: 'queues_and_documents',

  // Admin
  reassign_processor: "reassign_processor",

  // user apis
  reviewStatus: 'ReviewStatus_batch',
  toBeReviewStatus:'To_Be_ReviewStatus_batch',
  passwordStatus:'PasswordStatus_batch',
  pendingstatus: 'PendingStatus_batch',
  exportedstatus: 'ExportedStatus_batch',
  deletestatus: 'DeletedStatus_batch',
  deletedocuments: 'DeleteDocuments',
  zipbatch: 'zip_batch',
  upload_documents: 'upload_documents',
  admin_dashboard: 'AdminDashboard',
  admin_dashboard_batch: 'admin_dashboard_batch',
  open_cases_dashboard: 'open-cases-dashboard',
  userSetPassword: 'userSetPassword',
  rejectedStatus:'reject_tab_batch',
  searchStatus:'search_tab',

  //digitize section
  get_review_document: 'get_review_document',
  get_ui_validation: 'get_ui_validation',
  graphs: 'graphs',
  mark_as_reviewed: 'mark-as-reviewed',
  read_table: 'read_table',
  get_extracted_data: 'credit/Jumbled_words',
  //preProcessing && extraction
  caseProcessing: 'case_processing',
  extractionProcess: 'extraction',
  update_casedata: 'update_casedata',
  rejectstatus: 'rejectstatus',
  reject_job: 'reject_job',
  verify_casedata: 'verify_casedata',
  split_merge: 'split-merge',
  delete_unknown: 'delete_unknown',

  // Admin
  delete_caseId: 'Delete_CaseID',

  // Processor
  get_password: 'get_password',
  recover_caseid: 'recover-caseid',
  export_mis_excel_report: 'export_mis_excel_report',
  export_mis_pdf_report: "export_mis_pdf_report",
  mis_dashboard: 'mis_dashboard',
};

// All Constants
export const PTC = '#48454A'; // primary text color


// * Transaction types
// [
//   { label: "Insurance Premium", value: "Insurance Premium" },
//   { label: "Insurance Purchase", value: "Insurance Purchase" },
//   { label: "Medical Payments", value: "Medical Payments" },
//   { label: "Alcohol Purchase", value: "Alcohol Purchase" },
//   { label: "Minimum Balance Charges", value: "Minimum Balance Charges" },
//   { label: "Cheque Bounce Charges", value: "Cheque Bounce Charges" },
//   { label: "Negative Balance Charges", value: "Negative Balance Charges" },
//   { label: "SIP", value: "SIP" },
//   { label: "Mutual Fund Purchase", value: "Mutual Fund Purchase" },
//   { label: "Mutual Fund Sale", value: "Mutual Fund Sale" },
//   { label: "FD Payments", value: "FD Payments" },
//   { label: "FD Withdrawal", value: "FD Withdrawal" },
//   { label: "Society Maintenance", value: "Society Maintenance" },
//   { label: "Property Tax", value: "Property Tax" },
//   { label: "Adv Income Tax Payments", value: "Adv Income Tax Payments" },
//   { label: "Credit Card Payment", value: "Credit Card Payment" },
//   { label: "Insurance Payouts", value: "Insurance Payouts" },
//   { label: "Sweep In/Out", value: "Sweep In/Out" },
//   { label: "Salary", value: "Salary" },
//   { label: "Cheque", value: "Cheque" },
//   { label: "Net Banking", value: "Net Banking" },
//   { label: "Payment Gateway", value: "Payment Gateway" },
//   { label: "Auto Debit", value: "Auto Debit" },
//   { label: "Bank Charges", value: "Bank Charges" },
//   { label: "ATM Transaction", value: "ATM Transaction" },
//   { label: "Card Transaction", value: "Card Transaction" },
//   { label: "Bank Credit", value: "Bank Credit" },
//   { label: "Bill Pay", value: "Bill Pay" },
//   { label: "Loan", value: "Loan" },
//   { label: "Demand Draft", value: "Demand Draft" },
//   { label: "UPI", value: "UPI" },
//   { label: "Tax Payment", value: "Tax Payment" },
//   { label: "Others", value: "Others" },
// ]
export const transactionTypes = [
  { label: "ACH Credit", value: "ACH Credit" },
  { label: "ACH Debit", value: "ACH Debit" },
  { label: "Account Closure", value: "Account Closure" },
  { label: "Account/Investment Closure", value: "Account/Investment Closure" },
  { label: "Adv Income Tax Payments", value: "Adv Income Tax Payments" },
  { label: "Alcohol Purchase", value: "Alcohol Purchase" },
  { label: "ATM Transaction", value: "ATM Transaction" },
  { label: "Auto Debit", value: "Auto Debit" },
  { label: "Bank Charges", value: "Bank Charges" },
  { label: "Bank Credit", value: "Bank Credit" },
  { label: "Bill Pay", value: "Bill Pay" },
  { label: "Bill Reversal Payment", value: "Bill Reversal Payment" },
  { label: "Card Transaction", value: "Card Transaction" },
  { label: "Cash Deposit/Cash Management", value: "Cash Deposit/Cash Management" },
  { label: "Cheque", value: "Cheque" },
  { label: "Cheque Bounce Charges", value: "Cheque Bounce Charges" },
  { label: "Cheque Clearing", value: "Cheque Clearing" },
  { label: "Credit Card Payment", value: "Credit Card Payment" },
  { label: "Credit Card Repayment", value: "Credit Card Repayment" },
  { label: "Demand Draft", value: "Demand Draft" },
  { label: "Direct Tax Payment", value: "Direct Tax Payment" },
  { label: "EMI Transaction", value: "EMI Transaction" },
  { label: "FD Payments", value: "FD Payments" },
  { label: "FD Withdrawal", value: "FD Withdrawal" },
  { label: "Flexible FD", value: "Flexible FD" },
  { label: "Funds Transfer", value: "Funds Transfer" },
  { label: "Indirect Tax Payment", value: "Indirect Tax Payment" },
  { label: "Insurance Payouts", value: "Insurance Payouts" },
  { label: "Insurance Premium", value: "Insurance Premium" },
  { label: "Insurance Purchase", value: "Insurance Purchase" },
  { label: "Interest Credit'", value: "Interest Credit'" },
  { label: "Loan", value: "Loan" },
  { label: "Loan Repayment", value: "Loan Repayment" },
  { label: "Medical Payments", value: "Medical Payments" },
  { label: "Minimum Balance Charges", value: "Minimum Balance Charges" },
  { label: "Mutual Fund Purchase", value: "Mutual Fund Purchase" },
  { label: "Mutual Fund Sale", value: "Mutual Fund Sale" },
  { label: "Negative Balance Charges", value: "Negative Balance Charges" },
  { label: "Net Banking", value: "Net Banking" },
  { label: "Payment Gateway", value: "Payment Gateway" },
  { label: "PPF", value: "PPF" },
  { label: "Property Tax", value: "Property Tax" },
  { label: "POS Transaction", value: "POS Transaction" },
  { label: "Refund", value: "Refund" },
  { label: "Returned Cheque", value: "Returned Cheque" },
  { label: "Salary", value: "Salary" },
  { label: "SIP", value: "SIP" },
  { label: "Society Maintenance", value: "Society Maintenance" },
  { label: "Sweep In/Out", value: "Sweep In/Out" },
  { label: "Tax Payment", value: "Tax Payment" },
  { label: "UPI", value: "UPI" },
  { label: "Others", value: "Others" },
]





export const routers = ['/', '/processers', '/documents', '/fields'];
export const defaultDocTypes = [
  'Bank Statement',
  'Salary Slip',
  'Bank Statements',
  'Salary Slips',
];
export const customMuiTableRowStyle = {
  // height: '44px',
  // display: 'flex',
  // alignItems: 'center',
  // marginLeft: '1rem',
};
